<template>
  <div class="flex justify-center mt-10">
    <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
      <div class="text-center my-4">
        <h2 class="text-lg font-bold">
          Actualizar Fallo
        </h2>
      </div>
      <div class="flex flex-wrap content-around">
        <div class="w-full flex flex-col items-center py-5 px-2 ">
          <div class="px-2 w-full flex flex-col">
            <div class="mb-4">
              <label class="font-medium text-sm" v-if="model.tipo === 8">Impugnación</label>
              <label class="font-medium text-sm" v-else>Tutela</label>
              <Dropdown
                placeholder="Seleccione una tutela"
                :options="dataDropdown"
                optionLabel="observacion"
                v-model="tutelaSelected"
                class="w-full"
                @change="tutelaValor"
                >
                  <template #value="slotProps">
                    <div class="country-item country-item-value" v-if="slotProps.value">
                      <div>{{slotProps.value.accionanteFullname}}</div>
                    </div>
                    <span v-else>
                      {{slotProps.placeholder}}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex">
                      <div class="w-5/12">
                        <p style="color: #7F7E81" class="text-xs">
                          Nombre:
                        </p>
                        <p class="text-normal">
                          {{ slotProps.option.accionanteFullname }}
                        </p>
                      </div>
                      <div class="w-2/12">
                        <p style="color: #7F7E81" class="text-xs">
                          ID:
                        </p>
                        <p class="text-normal">
                          {{ slotProps.option.id }}
                        </p>
                      </div>
                      <div class="w-5/12">
                        <p style="color: #7F7E81" class="text-xs">
                          Observaciones:
                        </p>
                        <p class="text-normal">
                          {{ slotProps.option.observaciones.slice(0, 30) }}...
                        </p>
                      </div>
                    </div>
                  </template>
              </Dropdown>
              <MessageError :text="errors.base"/>
            </div>
            <div class="mb-4">
              <label class="font-medium text-sm">Observaciones</label>
              <Textarea
                v-model="model.observacion"
                placeholder="Observaciones"
                class="w-full"/>
              <MessageError :text="errors.observacion"/>
            </div>
            <div class="flex gap-4 mb-4">
              <div class="mb-4">
                <label class="font-medium text-sm">Fecha de notificacion</label>
                <input class="p-inputtext w-full"
                      type="datetime-local"
                      v-model="model.fechaNotificacion"
                >
                <MessageError :text="errors.fechaNotificacion"/>
              </div>
              <div class="mb-4">
                <label class="font-medium text-sm">Fecha de vencimiento</label>
                <input class="p-inputtext w-full"
                      type="datetime-local"
                      v-model="model.fechaVencimiento"
                >
                <MessageError :text="errors.fechaVencimiento"/>
              </div>
            </div>
           <div class="px-2 w-full">
              <div style="max-height: 150px; overflow-y: auto;">
                <div class="flex justify-between" v-for="(item, key) in adjuntos" :key="key">
                  <div class="flex mt-2">
                    <i class="pi pi-file-pdf mr-2"></i>
                    <p>{{ item.fileName }}</p>
                  </div>
                  <div>
                    <button type="button" @click="verPdf(item.urlPath)"><i class="pi pi-eye mr-2 text-blue-600"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full mb-4 flex flex-col">
              <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                Agregar nuevo soporte
              </button>
              <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                <div>
                  <div>
                    <label class="text-sm" for="titulo">Titulo</label>
                    <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                    class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                  </div>
                  <div class="my-2">
                    <label class="text-sm">Adjunte el documento</label>
                    <div class="mt-2">
                      <button type="button" class="w-full">
                        <FilePond ref="pond"/>
                      </button>
                    </div>
                  </div>
                </div>
                <template class="text-center" #footer>
                    <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                </template>
            </Dialog>
            </div>
          </div>
        </div>
        <div class="w-full flex justify-between px-2 mb-9">
          <Button label="Cancelar"
                  class="bg-blue-900 p-button-outlined"
                  style="width: 244px;"
                  @click="$router.go(-1)"
          />
          <Button label="Actualizar"
                  class="bg-blue-900"
                  style="width: 244px;"
                  type="button"
                  @click="handleNext"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storeTutela from '../../../../store/tutela.store'
import storeImpugnaciones from '../../../../store/impugnaciones.store'
import storeFallos from '@/apps/pharmasan/juridica/procesosJuridicos/store/fallos.store'
import filepondStore from '../../../../store/filepond.store'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import { onMounted, ref, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'
import dayjs from 'dayjs'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'

export default {
  name: 'actualizarFallos',
  components: {
    FilePond
  },
  setup () {
    /** computed **/
    const fallo = computed(() => {
      return storeFallos.getters.fallo
    })
    const tutelas = computed(
      () => storeTutela.getters.tutelas.rows
    )
    const impugnaciones = computed(
      () => storeImpugnaciones.getters.impugnaciones.rows
    )
    const adjunto = computed(() => filepondStore.getters.adjunto)

    /** intancias **/

    const route = useRoute()
    const router = useRouter()
    const tiposProcesos = ref([])
    const dataDropdown = ref([])
    const displayMaximizable = ref(false)
    const adjuntos = ref([])
    const pond = ref()
    const title = ref('')
    const id = ref(null)
    const tutelaSelected = ref()
    const _PdfService = new PdfService()

    /** state **/
    const schema = yup.object({
      tipo: yup.number().nullable().required('El campo es requerido').label(''),
      base: yup.number().nullable().required('El campo es requerido').label(''),
      observacion: yup.string().nullable().required('El campo es requerido').label(''),
      fechaNotificacion: yup.date().nullable().required('El campo es requerido').label('')
      // fechaVencimiento: yup.date().nullable().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('tipo')
    useField('base')
    useField('observacion')
    useField('fechaNotificacion')
    // useField('fechaVencimiento')
    /** data **/

    /** methodos **/
    const tutelaValor = () => {
      model.base = tutelaSelected.value.id
    }
    const handleNext = handleSubmit(async (values) => {
      if (!values) return console.error('Error => validation')
      if (values.fechaVencimiento === '') {
        values.fechaVencimiento = null
      }
      storeFallos.commit('setUpdateFallo', values)
      storeFallos.dispatch('updateFallo', route.params.id).then(({ data }) => {
        Swal.fire({
          title: 'Exito',
          text: 'Fallo actualizado con exito',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      })
    })

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        storeFallos.commit('setUpdateFallo', {
          paths: adjuntos.value
        })
        storeFallos.dispatch('updateFallo', route.params.id).then(({ data }) => {
          Swal.fire({
            title: 'Exito',
            text: 'Documento insertado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              storeFallos.dispatch('findFallo', id.value)
            }
          })
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }
    //
    // watch(fallo.value, () => {
    //   model.tipo = fallo.value.tipo
    //   model.base = fallo.value.base
    //   model.observacion = fallo.value.observacion
    //   model.fechaNotificacion = dayjs(fallo.value.fechaNotificacion).format('YYYY-MM-DD')
    //   model.fechaVencimiento = dayjs(fallo.value.fechaVencimiento).format('YYYY-MM-DD')
    //   id.value = fallo.value.id
    // })

    /** hooks **/
    onMounted(() => {
      id.value = route.params.id
      storeFallos.dispatch('findFallo', id.value).then((data) => {
        if (data.tipo === 8) {
          storeImpugnaciones.dispatch('getPaginate').then(() => {
            dataDropdown.value = impugnaciones.value.map((item) => {
              item.accionanteFullname = item.nombre
              return {
                ...item
              }
            })
            tutelaSelected.value = impugnaciones.value.find(impugnacion => impugnacion.id === data.base)
          })
        } else {
          storeTutela.dispatch('getPaginate').then(() => {
            dataDropdown.value = tutelas.value.filter(a => a.fallo === false)
            tutelaSelected.value = tutelas.value.find(tutela => tutela.id === data.base)
          })
        }
        model.base = data.base
        model.tipo = data.tipo
        // model.base = data.base
        model.observacion = data.observacion
        model.fechaNotificacion = dayjs(data.fechaNotificacion).format('YYYY-MM-DDTHH:mm')
        model.fechaVencimiento = data.fechaVencimiento === 'No tiene' ? null : dayjs(data.fechaVencimiento).format('YYYY-MM-DDTHH:mm')
        adjuntos.value = data.paths
      })
    })

    return {
      errors,
      model,
      handleNext,
      tiposProcesos,
      dataDropdown,
      tutelas,
      displayMaximizable,
      openMaximizable,
      closeMaximizable,
      title,
      adjunto,
      adjuntos,
      pond,
      tutelaSelected,
      tutelaValor,
      fallo,
      verPdf
    }
  }
}
</script>

<style lang="scss">
.p-message .p-message-wrapper {
  padding: 0.5rem 1.5rem;
}
</style>
